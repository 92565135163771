<template>
    <div class="main-container imgback" style="width: 100vw; height: 100vh">
        <div id="particles-js"></div>
        <div class="countdown-bg"></div>
        <div class="error-screen" style="color: #0E5E4E">
        </div>
    </div>
</template>

<script>
export default {
    name: 'UnauthorizedIndex',

    mounted() {
    },

    methods: {}
}
</script>

<style scoped>
.imgback{
    background-image: url("../../assets/img/404_com_logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1400px 775px;
    background-color: #77BF99;
}

</style>
